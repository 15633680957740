import React from 'react';
import "./Header.scss";

const Header = () => (
    <div className="header__title-box">
        <h1 className="header-primary">
            <span className="header-primary--main">
                Eric Lammers
                <div className="u-border-bottom-div u-delayed-fade-in"></div>
            </span>
            <span className="header-primary--sub">Full Stack Developer</span>
        </h1>
    </div>
);

export default Header;