import React, { useState } from 'react';
import CardFront from './components/CardFront/CardFront';
import CardBack from './components/CardBack/CardBack';
import "./Card.scss";

const Card = ({
    title,
    description,
    techStack,
    buttonLinks
}) => {
    const [isRotated, setIsRotated] = useState(false);

    return (
        <div className={`flip-card ${isRotated ? 'flip-card--rotated' : ''}`} onClick={() => setIsRotated(!isRotated)}>
            <div className="flip-card__face flip-card__face--front">
                <CardFront title={title} description={description} />
            </div>
            <div className="flip-card__face flip-card__face--back">
                <CardBack techStack={techStack} buttonLinks={buttonLinks} />
            </div>
        </div>
    );
}

export default Card;