import React from 'react';
import ButtonLinks from 'components/ButtonLinks/ButtonLinks';
import TechStack from './components/TechStack/TechStack';
import "./CardBack.scss";

const CardBack = ({techStack, buttonLinks}) => (
    <div className="card-back">
        <TechStack technologyList={techStack} />
        {buttonLinks && buttonLinks.length !== 0 ? <ButtonLinks buttons={buttonLinks} /> : null}
    </div>
);

export default CardBack;