import React, { useState, useEffect } from 'react';
import debounce from "services/debounce";
import throttle from "services/throttle";
import ExpandableInfoBar from './components/ExpandableInfoBar/ExpandableInfoBar';

const Page = ({ children, sectionIds, listedSectionIds }) => {
    const [currentSection, setCurrentSection] = useState(sectionIds[0]);
    const [manuallyScrolling, setManuallyScrolling] = useState(true);

    const isAlreadyCurrentSection = sectionId => sectionId === currentSection;

    const isHighInThePage = (yLocation) => {
        const windowHeight = window.innerHeight;
        return yLocation < (2 * windowHeight)/5;
    };

    const determineCurrentSection = () => {
        let candidateCurrentSection = sectionIds[0];

        for(let i = 1; i < sectionIds.length; i++) {
            const sectionId = sectionIds[i];
            const top = document.getElementById(sectionId).getBoundingClientRect().top;
            if(isHighInThePage(top)) {
                candidateCurrentSection = sectionId;
            }
        }

        if(!isAlreadyCurrentSection(candidateCurrentSection)) {
            setCurrentSection(candidateCurrentSection);
        }
    };

    // Called incrementally
    const determineCurrentSectionThrottled = throttle(() => {
        if(manuallyScrolling) {
            determineCurrentSection();
        }
    }, 100);

    // Called once scrolling ends
    const determineCurrentSectionDebounced = debounce(() => {
        if(!manuallyScrolling) {
            setManuallyScrolling(true);
        } else {
            determineCurrentSection();
        }
    }, 50);

    const scrollToSection = (section) => {
        setManuallyScrolling(false);
        setCurrentSection(section);
        document.getElementById(section).scrollIntoView({
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', determineCurrentSectionThrottled);
        window.addEventListener('scroll', determineCurrentSectionDebounced);

        return function cleanup() {
            window.removeEventListener('scroll', determineCurrentSectionThrottled);
            window.removeEventListener('scroll', determineCurrentSectionDebounced);
        };
    });
    return (
         <div>
             <ExpandableInfoBar 
                sectionNames={listedSectionIds}
                currentSection={currentSection}
                scrollToSection={scrollToSection}
             />
             { children }
        </div>
    );
};

export default Page;