import React from 'react';
import Technology from "./components/Technology/Technology";
import "./Technologies.scss";
import "./components/Technology/Technology.scss";

const technologies = [
    {
        title: "Proficient",
        list: [
            {
                name: "C#",
            },
            {
                name: ".Net Core",
            },
            {
                name: "Kubernetes",
            },
            {
                name: "Helm",
            },
            {
                name: "Prometheus",
            },
            {
                name: "Docker",
            },
            {
                name: "Typescript",
            },
            {
                name: "Javascript",
            },
            {
                name: "Git",
                image: require("./img/git.png"),
            }
        ]
    },
    {
        title: "Familiar",
        list: [
            {
                name: "CSS",
                image: require("./img/css.png"),
            },
            {
                name: "HTML",
                image: require("./img/html.png"),
            },
            {
                name: "Node",
                image: require("./img/node.png"),
            },
            {
                name: "SCSS",
                image: require("./img/sass.png"),
            },
            {
                name: "React",
                image: require("./img/react.png"),
            },
            {
                name: "Redis",
            },
            {
                name: "Webpack",
                image: require("./img/webpack.png"),
            },
            {
                name: "Gitlab CI",
                image: require("./img/gitlab.png"),
            },
        ]
    },
    {
        title: "Learning",
        list: [
            {
                name: "Terraform",
                image: require("./img/graphql.png"),
            }
        ]
    },
]

const Technologies = () => {
    const technologySections = [];

    technologies.forEach(section => {
        technologySections.push((
            <div className="technologies__section">
                <div className="technologies__title-box">
                    <h3 className="technologies__title">{section.title}</h3>
                </div>
                <div className="technologies__list">
                    {section.list.map(technology => (
                        <Technology {...technology} key={technology.name} />
                    ))}
                </div>
            </div>
        ));
    });

    return (
        <div className="technologies">
            { technologySections }
        </div>
    );
};

export default Technologies;