import React, { useState } from 'react';
import './InterestCard.scss';

const InterestCard = ({ title, description, subTitle, list, iconClassName }) => {
    const [isRotated, setIsRotated] = useState(false);

    return (
        <div className={`interest-card ${isRotated ? 'interest-card--rotated' : ''}`} onClick={() => setIsRotated(!isRotated)}>
            <div className="interest-card__face interest-card__face--front">
                <i className={`interest-card__icon  ${iconClassName}`}></i>
                <h3 className="interest-card__title">
                    {title}
                </h3>
                <p className="interest-card__details">
                    {description}
                </p>
            </div>
            <div className="interest-card__face interest-card__face--back">
                <div className="interest-card__list-box">
                    <h4 className="interest-card__title">
                        {subTitle}
                    </h4>
                    <ul className="interest-card__list">
                        {list.map(item => (
                            <li className="interest-card__list-item">
                                <a className="interest-card__list-item-link" href={item.link} target="_blank">{item.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default InterestCard;