import React from 'react';
import "./CardFront.scss";

const convertToDashedIdentifier = (title) => {
    let lowercaseTitle = title.toLowerCase();
    return lowercaseTitle.replace(/ /g, '-');
}

const CardFront = ({title, description}) => (
    <div className="card-front">
        <div className={`card-front__picture card-front__picture--${convertToDashedIdentifier(title)}`}></div>
        <h4 className="card-front__heading">
            <span className="card-front__heading-span">{title}</span>
        </h4>
        <div className="card-front__details">
            {description.map(paragraph => <p className="card-front__paragraph">{paragraph}</p>)}
        </div>
    </div>
);

export default CardFront;