import React from 'react';
import './Education.scss';

// TODO - Clean this component up
// Create a child component for each card
const Education = () => (
    <div className="education">
        <div className="education__degree education__card">
            <div className="education__header education__header--school">
                <div className="education__sub-header">
                    <span className="education__type education__label">
                        Degree
                    </span>
                    <span className="education__date-range education__label">
                        Sept 2016 - April 2019
                    </span>
                </div>
                <div className="education__degree-description">
                    <h3 className="education__degree-title">
                        Specialization in Computer Science
                    </h3>
                    <h4 className="education__degree-sub-title">
                        Bachelor of Science
                    </h4>
                </div>
            </div>
            <div className="education__content">
                <span className="education__school-name">
                    University of Western Ontario
                </span>
                <span className="education__achievements">
                    Graduated with Distinction
                </span>
                <span className="education__achievements">
                    Dean's Honor List
                </span>
            </div>            
        </div>
        <div className="education__degree education__card">
            <div className="education__header education__header--css">
                <div className="education__sub-header">
                    <span className="education__type education__type--course education__label">
                        Course
                    </span>
                    <span className="education__date-range education__label">
                        Nov 2019 - Present
                    </span>
                </div>
                <div className="education__degree-description">
                    <h3 className="education__degree-title">
                        Advanced CSS and Sass: Flexbox, Grid, Animations and More!
                    </h3>
                </div>
            </div>
            <div className="education__content">
                <span className="education__school-name">
                    Udemy.com
                </span>
                <span className="education__achievements">
                    28 hours of content
                </span>
                <span className="education__achievements">
                    Content covers mastering flexbox, CSS Grid, responsive design and more.
                </span>
            </div>            
        </div>
        <div className="education__degree education__card">
            <div className="education__header education__header--deploy">
                <div className="education__sub-header">
                    <span className="education__type education__type--course education__label">
                        Course
                    </span>
                    <span className="education__date-range education__label">
                        November 2019
                    </span>
                </div>
                <div className="education__degree-description">
                    <h3 className="education__degree-title">
                        Deploy and Run Apps with Docker, Kubernetes, Helm, Rancher
                    </h3>
                </div>
            </div>
            <div className="education__content">
                <span className="education__school-name">
                    Udemy.com
                </span>
                <span className="education__achievements">
                    24 hours of content
                </span>
                <span className="education__achievements">
                    Covered the fundamentals of Docker and Kubernetes. It also touched on helm, rancher, CI and cloud kubernetes services.
                </span>
            </div>            
        </div>
    </div>
);

export default Education;