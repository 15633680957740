import React from 'react';
import "./TimelineItem.scss";

const TimelineItem = ({
    position,
    company,
    dateRange,
    jobDetails,
    id,
}) => (
    <div className="timeline-item">
        <div className={`timeline-item__header timeline-item__header--${id}`}>
            <span className="timeline-item__date-range">
                {dateRange}
            </span>
            <div className="timeline-item__job-description">
                <h3 className="timeline-item__job-title">
                    {position}
                </h3>
                <h4 className="timeline-item__company">
                    {company}
                </h4>
            </div>
        </div>
        <ul className="timeline-item__job-details">
            {jobDetails.map(jobDetail => <li className="timeline-item__job-detail">{jobDetail}</li>)}
        </ul>
    </div>
);

export default TimelineItem;