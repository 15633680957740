import React from 'react';
import './Footer.scss';

const Footer = () => (
    <footer className="footer">
        <div className="footer__connect-info">
            <h3 className="footer__connect-title">Connect</h3>
            <span>ericjameslammers@gmail.com</span>
            <a href="https://www.linkedin.com/in/ericjameslammers/" target="_blank" className="footer__link">Linkedin</a>
            <a href="https://gitlab.com/ericlammers"  target="_blank" className="footer__link">Gitlab</a>
        </div>
        <div className="footer__credits">
            <h3 className="footer__credits-title">Recognition</h3>
            <span className="footer__credit">
                Sites design was inspired by Jonas Schmedtmann <a href="https://www.udemy.com/course/advanced-css-and-sass/" className="footer__link" target="_blank">Natours project</a>
            </span>
            <span className="footer__credit">
                All third party images are from <a href="https://unsplash.com/" className="footer__link" target="_blank">Unsplash</a>
            </span>
        </div>
    </footer>
);

export default Footer;