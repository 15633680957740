import React from 'react';
import Section from 'components/Section/Section';
import Timeline from './components/Timeline/Timeline';
import Technologies from './components/Technologies/Technologies';
import Header from './components/Header/Header';
import Projects from './components/Projects/Projects';
import Interests from './components/Interests/Interests';
import Education from './components/Education/Education';
import Footer from './components/Footer/Footer';
import Page from '../../components/Page/Page';
import "./Home.scss";

const Home = () => {
  const header = 'header';
  const projects = "projects";
  const interests = 'interests';
  const experience = "experience";
  const education = "education";
  const technologies = 'technologies';
  const connect = "connect";

  const sectionIds = [
    header,
    projects,
    education, 
    experience,
    interests,
    technologies,
    connect,
  ];

  const listedSectionIds = [
    projects,
    experience,
    education,
    connect,
  ];

  return (
    <Page sectionIds={ sectionIds } listedSectionIds={listedSectionIds}>
        <div>
          <Section sectionName="header">
            <Header />
          </Section>

          <Section sectionName={ projects }>
            <Projects />
          </Section>

          <Section sectionName={ experience }>
            <Timeline/>
          </Section>

          <Section sectionName={education}>
            <Education />
          </Section>

          <Section sectionName="interests">
            <Interests />
          </Section>

          <Section sectionName="technologies">
            <Technologies />
          </Section>

          <Section sectionName={ connect }>
            <Footer />
          </Section>
        </div>
    </Page>
  );
}

export default Home;