import React from 'react';
import TimelineItem from "./components/TimelineItem/TimelineItem";
import "./Timeline.scss";

const timelineItems = [
    {
        position: "Senior Software Developer",
        company: "Geotab",
        dateRange: "August 2021  -  Present",
        jobDetails: [
            "Implemented a highly scalable, resilient and performant data ingestion gateway that processes 10,000 records a second",
            "The gateway was built as .Net Core microservices and leverages message queues",
            "The application is deployed to Kubernetes through helm and expose Prometheus metrics"
        ],
        id: "work-study"
    },
    {
        position: "Software Developer",
        company: "Autodata Solutions Company",
        dateRange: "May 2019  -  Present",
        jobDetails: [
            "Contributed to the early stages of design and estimation for new front end projects",
            "Handled configurations, builds and deploys for the Compare front end application",
            "Simplified how the client integrates with our React widget by redesigning the integration API and working directly with the SA team to create a detailed integration document", 
        ],
        id: "autodata"
    },
    {
        position: "Software Developer, Intern",
        company: "Autodata Solutions Company",
        dateRange: "May 2017  -  August 2018",
        jobDetails: [
            "Helped set the company standard for front end development by taking a lead role in the solutioning and development of our company’s first React application", 
            "Reduced the pressure on the technical lead by taking over his responsibility of assigning work to, communicating with and reviewing the code of the offshore team", 
            "Implemented the business logic for a Spring API by writing and unit testing several service classes", 
            "Identified several bugs in an ETL by writing a suite of automated tests using Spock that ran the ETL and verified that the correct data was stored in its MySQL database", 
        ],
        id: "autodata-intern"
    },
    {
        position: "Full Stack Web Developer, Work / Study Program",
        company: "University of Western Ontario",
        dateRange: "September 2017  -  March 2018",
        jobDetails: [
            "Built a web app using Express that allowed admin users to update the data in an existing IOS Apps MongoDB database", 
        ],        
        id: "work-study"
    },
];

const Timeline = () => {
    const timelineContent = timelineItems.map(item => (
        <div className="timeline__section">
            <div className="timeline__bar"></div>
            <TimelineItem {...item} />
        </div>
    ));

    return (
        <div className="timeline">
            {timelineContent}
        </div>
    );
};

export default Timeline;