import React, { useState } from 'react';
import InfoBar from './components/InfoBar/InfoBar';
import InfoDropdown from './components/InfoDropdown/InfoDropdown';

const ExpandableInfoBar = ({ sectionNames, currentSection, scrollToSection, scrollToTopOfPage}) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const closeDropdown = () => {
        console.log("Close Dropdown");
        setShowDropdown(false);
    }

    const openDropdown = () => {
        console.log("Open Dropdown");
        setShowDropdown(true);
    }

    return (
        <div>
            <InfoBar 
                sectionNames={sectionNames} 
                currentSection={currentSection}
                scrollToSection={scrollToSection}
                onInfoClicked={openDropdown} 
            />
            <InfoDropdown 
                sectionNames={sectionNames} 
                currentSection={currentSection}
                scrollToSection={scrollToSection}
                onCloseClicked={closeDropdown} 
                hidden={!showDropdown} 
            />
        </div>
    );
};

export default ExpandableInfoBar;