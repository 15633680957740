import React from 'react';
import Card from './components/Card/Card';
import "./Projects.scss";

const projects = [
    {
        title: 'Tourny',
        description: [
            'A tournament management web application',
            'Visit the pre-release version of the site at https://tourny.ca'
        ],
        techStack: ['Typescript', 'Node', 'React', 'SCSS', 'GraphQL', 'Apollo', 'Postgres', 'Kubernetes', 'Terraform'],
        buttonLinks: [
            {
                name: 'View Site (pre-release)',
                link: 'https://tourny.ca'
            }
        ],
    },
    {
        title: 'Job Site Manager App',
        description: [
            'Job Site Manager is a web application that helps companies who operate at multiple different job sites manage and track which employees are assigned to each site.', 
            'The front end is built with React and Redux. The back end uses Spring Boot and a MySQL database.'
        ],
        techStack: ['React', 'Redux', 'Styled-Components', 'CSS Flex', 'Jest', 'Spring Boot', 'MySQL', 'JUnit', 'Docker'],
        buttonLinks: [
            {
                name: 'View Git Project',
                link: 'https://gitlab.com/Job-Site-Manager'
            }
        ],
    },
    {
        title: 'Portfolio V2',
        description: [
            'Portfolio V2 is the website you are currently viewing. It displays some of the personal and professional work I have done as a software developer.', 
            'The site is built using React and uses SASS for styling.'
        ],
        techStack: ['React', 'SASS', 'Flexbox', 'Create React App'],
        buttonLinks: [
            {
                name: 'View Git Project',
                link: 'https://gitlab.com/ericlammers/portfolio-v2'
            }
        ],
    },
    {
        title: 'Portfolio V1',
        description: [
            'Protfolio V1 is the first version of my portfolio website.', 
            'It is built with React and hosted on github pages.'
        ],
        techStack: ['React', 'ES6', 'SCSS', 'Flexbox', 'React Router', 'Github Pages', 'Create React App',],
        buttonLinks: [
            {
                name: 'Visit Site',
                link: 'https://ericlammers.github.io/portfolio/'
            },
            {
                name: 'View Git Project',
                link: 'https://gitlab.com/ericlammers/portfolio'
            }
        ],
    },
    {
        title: 'Trades Watch',
        description: [
            'Trades Watch was built as part of an acedemic group project and leverages a microservice architecture.', 
            'My role on the project was to handle containerizing and deploying each of the microservices into IBM Cloud\'s kubernetes service. I also implemented the API gateway and service registry.'
        ],
        techStack: ['Kubernetes', 'Docker', 'IBM Cloud', 'Express', 'MongoDB'],
        buttonLinks: [
            {
                name: 'View Kube Config',
                link: 'https://gitlab.com/ericlammers/trade-watch-kubernetes-configurations'
            },
            {
                name: 'View Gateway Project',
                link: 'https://gitlab.com/ericlammers/trade-watch-api-gateway-and-register'
            }
        ],
    }
]

const Projects = () => (
    <div className="projects">
        {projects.map(project => <Card {...project} />)}
    </div>
);

export default Projects;