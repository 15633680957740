import React from 'react';
import Logo from '../Logo/Logo';
import SectionList from '../SectionList/SectionList';
import "./InfoDropdown.scss";

const InfoDropdown = ({
    sectionNames, 
    currentSection,
    scrollToSection,  
    onCloseClicked, 
    hidden
}) => {
    const onSectionClicked = (sectionName) => {
        scrollToSection(sectionName);
        onCloseClicked();
    } 

    return (
        <div className={`info-dropdown ${hidden ? '' : 'info-dropdown--selected'}`}>
            <Logo className="info-dropdown__logo" clicked={onCloseClicked}/>
            <div className="info-dropdown__close-button" onClick={onCloseClicked} >
                X
            </div>
            <SectionList sectionNames={sectionNames} sectionClicked={onSectionClicked} />
        </div>
    );
};

export default InfoDropdown;