import React from 'react';
import "./TechStack.scss";

const TechStack = ({technologyList}) => (
    <div className="tech-stack">
        <h3 className="tech-stack__title">
            Tech Stack
        </h3>
        <div className="tech-stack__list">
            <ul>
                {technologyList.map(technology => <li>{technology}</li>)}
            </ul>
        </div>
    </div>
);

export default TechStack;