import React from 'react';
import "./Logo.scss";

const Logo = ({className, clicked}) => {

    const onClick = () => {
        if(clicked) {
            clicked();
        }

        document.getElementById('header').scrollIntoView({behavior: 'smooth'});
    } 
    return (
        <div className={`logo ${className}`} onClick={onClick} >
            <img src={require('./logo-white.png')} alt="Logo" className="logo__image" />
        </div>
    );
}

export default Logo;