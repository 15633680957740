import React from 'react';
import "./SectionList.scss";

const SectionList = ({sectionNames, horizontal, currentSection, sectionClicked}) => (
    <div className={`section-list ${horizontal ? 'section-list--horizontal' : ''}`}>
        { sectionNames.map(sectionName => (
            <a 
                className={`section-list__button ${currentSection === sectionName ? 'section-list__button--selected' : ''}`}
                onClick={() => sectionClicked(sectionName)}
            >
                {sectionName}
            </a>
        ))}
    </div>
);

export default SectionList;