import React, { useEffect, useState } from 'react';
import Logo from '../Logo/Logo';
import SectionList from '../SectionList/SectionList';
import InfoIcon from './components/InfoIcon/InfoIcon';
import "./InfoBar.scss";

const InfoBar = ({ sectionNames, currentSection, scrollToSection, onInfoClicked }) => {
    const [affixed, setAffixed] = useState(false);

    const isAffixedCheck = () => {
        if(window.scrollY === 0){
            setAffixed(false);
        } else {
            setAffixed(true);
        }
    }

    useEffect(() => {
        console.log('Set Event Listener');
        window.addEventListener("scroll", isAffixedCheck);
        return () => window.removeEventListener("scroll", isAffixedCheck);
    }, []);

    return (
        <div className={`info-bar ${affixed ? 'info-bar--affixed' : ''} u-delayed-fade-in`}>
            <Logo />
            <SectionList 
                sectionClicked={scrollToSection} 
                sectionNames={sectionNames} 
                horizontal 
                currentSection={currentSection}
            />
            <InfoIcon onClick={onInfoClicked} />
        </div>
    );
};

export default InfoBar;