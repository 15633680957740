import React from 'react';
import InterestCard from './components/InterestCard/InterestCard';
import './Interests.scss';

const interests = [
    {
        title: 'Podcasts',
        description: 'Listening to tech podcasts is a great way for me to stay up to date with the current trends in technology.',
        subTitle: 'Favourites',
        list: [
            {
                link: 'https://www.npr.org/series/490248027/how-i-built-this',
                name: 'How I Built This'
            },
            {
                link: 'https://syntax.fm/',
                name: 'Syntax'
            },
            {
                link: 'https://www.codingblocks.net/',
                name: 'Coding Blocks'
            },
            {
                link: 'https://tim.blog/podcast/',
                name: 'The Tim Ferris Show Blocks'
            }
        ],
        iconClassName: 'icon-music-headphones'
    },
    {
        title: 'Tutorials',
        description: 'I find tutorials are useful for quickly ramping up on a new technology or concepts.',
        subTitle: 'Favourites',
        list: [
            {
                link: 'https://www.freecodecamp.org/news/learn-kubernetes-in-under-3-hours-a-detailed-guide-to-orchestrating-containers-114ff420e882/',
                name: 'Learn Kubernetes in Under 3 Hours: A Detailed Guide to Orchestrating Containers'
            },
            {
                link: 'https://medium.com/@muneebsajjad/git-flow-explained-quick-and-simple-7a753313572f',
                name: 'Git Flow Explained'
            },
            {
                link: 'https://medium.com/@alexmngn/how-to-better-organize-your-react-applications-2fd3ea1920f1',
                name: 'How to better organize your React applications?'
            },
            {
                link: 'https://www.robertcooper.me/get-started-with-typescript-in-2019/',
                name: 'Get Started With Typescript in 2019'
            },
            {
                link: 'https://www.freecodecamp.org/news/graphql-zero-to-production-a7c4f786a57b/',
                name: 'How to build a full GraphQL server with Node.js'
            },
        ],
        iconClassName: 'icon-basic-webpage-txt'
    },
    {
        title: 'Textbooks',
        description: 'Textbooks are a great way for me to dive deeper into topics that I have some practical experience with it.',
        subTitle: 'Favourites',
        list: [
            {
                link: 'https://learning.oreilly.com/library/view/fundamentals-of-software/9781492043447/',
                name: 'Fundamentals of Software Architecture'
            },
            {
                link: 'https://www.manning.com/books/kubernetes-in-action',
                name: 'Kubernetes in Action'
            },
            {
                link: 'https://www.oreilly.com/library/view/software-architecture-in/9780132942799/',
                name: 'Software Architecture in Practise'
            },
            {
                link: 'https://www.oreilly.com/library/view/clean-code/9780136083238/',
                name: 'Clean Code'
            },
            {
                link: 'https://www.oreilly.com/library/view/the-art-of/9781617290893/',
                name: 'The Art of Unit Testing'
            },
            {
                link: 'https://www.fullstackreact.com/',
                name: 'Fullstack React: The Complete Guide to ReactJs and Friends'
            },
            {
                link: 'https://www.manning.com/books/spring-in-action-fourth-edition',
                name: 'Spring in Action, 4th Edition'
            },
        ],
        iconClassName: 'icon-basic-book'
    },
];

const Interests = () => (
    <div className="interests">
        {interests.map(interest => <InterestCard {...interest} />)}
    </div>
);

export default Interests;
