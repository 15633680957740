import React from 'react';
import Home from 'scene/Home/Home';
import "styles/main.scss";

const App = () => (
  <div>
    <Home />
  </div>
);

export default App;
