import React from 'react';
import "./InfoIcon.scss";

const InfoIcon = ({onClick}) => (
    <div className="info-icon" onClick={onClick}>
        <span className="info-icon__bar"></span>
        <span className="info-icon__bar"></span>
        <span className="info-icon__bar"></span>
    </div>
)

export default InfoIcon;